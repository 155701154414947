import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, PrimaryButton, Seo } from 'components';
import { AppState } from 'state/types';
import HowToSection from './components/HowToSection';
import ThankYouSection from './components/ThankYouSection';
import OrderSummary from './components/OrderSummary';
import Header from 'components/Header';
import { usePageView, useQuizData } from 'utils/hooks';
import Tracking from 'utils/tracking';
import Disclaimer from 'components/Disclaimer';
import PaymentSteps from '../payments/components/PaymentSteps';
import { tablet } from 'styles/breakpoints';
import { normalizeStates } from 'utils/localization';
import { config } from 'config';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f6f6f6;
  min-height: calc(100vh - 69px);
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;

const DownloadContainer = styled.div<{ isWhite: boolean }>`
  width: 100%;
  padding: 3rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    padding: 1.5rem 1rem 1rem;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DynamicImageStyled = styled(DynamicImage)`
  max-width: 26.79688rem;
  height: 20rem;
  width: 100%;
  @media ${tablet} {
    height: unset;
  }
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-family: 'Afacad', sans-serif;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  max-width: 28.75rem;
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media ${tablet} {
    max-width: 21.4375rem;
  }
`;

const ButtonStyled = styled(PrimaryButton)`
  max-width: 28.75rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.mgSecondary100};
  border-color: ${({ theme }) => theme.colors.mgSecondary100};
`;

const Success: FC<RouteComponentProps> = () => {
  const code = useSelector((state: AppState) => state.user.code);
  const data = useQuizData('success');

  const {
    selected_plans_options,
    upsell_products,
    geolocation,
    quiz_answers,
    user,
  } = useSelector((state: AppState) => state.user);

  const { subContainer } = selected_plans_options;
  const hasBoughtUpsellApp = upsell_products.some(
    obj => obj['is_subscription'] === true,
  );
  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    client_code: code,
    zipCode: quiz_answers?.shipping_address?.postcode?.toLowerCase(),
    phoneNumber: quiz_answers?.shipping_address?.phoneNumber,
  });

  useEffect(() => {
    if (!code) {
      return;
    }

    Tracking.logEvent({
      event: 'email_left',
      client_code: code,
    });
  }, [code]);

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header
        logoVariant="center"
        hasDivider={false}
        hasShadow={true}
        sticky={true}
        color="white"
      />
      <Container>
        {code ? (
          <>
            <PaymentStepsStyled
              firstStepTitle={data.paymentSteps.firstStepTitle.text}
              secondStepTitle={data.paymentSteps.secondStepTitle.text}
              thirdStepTitle={data.paymentSteps.thirdStepTitle.text}
              isSuccess={true}
            />
            {subContainer || hasBoughtUpsellApp ? (
              <DownloadContainer isWhite={false}>
                <ImgContainer>
                  <DynamicImageStyled
                    src="upsell/app.png"
                    alt="Moongrade app"
                  />
                  <Title>{data?.appTitle}</Title>
                  <ButtonStyled
                    onClick={() => window.open(config.WEB_APP_URL, '_system')}
                  >
                    {data?.appButtonText}
                  </ButtonStyled>
                </ImgContainer>
              </DownloadContainer>
            ) : (
              <ThankYouSection {...data?.section1} />
            )}

            <OrderSummary {...data?.section3} isWhite={true} />
          </>
        ) : (
          <DownloadContainer isWhite={false}>
            <ImgContainer>
              <DynamicImageStyled src="upsell/app.png" alt="Moongrade app" />
              <Title>{data?.appTitle}</Title>
              <ButtonStyled
                onClick={() => window.open(config.WEB_APP_URL, '_system')}
              >
                {data?.appButtonText}
              </ButtonStyled>
            </ImgContainer>
          </DownloadContainer>
        )}
      </Container>
      {/* <Disclaimer bgColor="#f3f3f8" text={data?.text} /> */}
    </>
  );
};

export default Success;
